
import {
  IonProgressBar,
  IonCard,
  IonCardContent,
  IonIcon,
  IonLabel,
  isPlatform,
  actionSheetController,
} from "@ionic/vue";
import { Camera, CameraResultType } from '@capacitor/camera';
import { closeOutline, checkmarkOutline, refreshOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import axios from "axios";
import Button from "@/components/Button.vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/services/api.service";

export default defineComponent({
  name: "Upload",

  components: {
    IonProgressBar,
    IonCard,
    IonCardContent,
    IonIcon,
    IonLabel
  },

  props: {
    multipleFiles: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },

  data() {
    return {
      files: [] as File[],
      chunks: [] as any,
      completedUploads: [] as any
    };
  },

  setup() {
    const file = ref<HTMLInputElement>();
    const { t } = useI18n();

    return {
      t,
      file,
      closeOutline,
      refreshOutline,
      checkmarkOutline
    };
  },

  methods: {
    getFileIds() {
      return this.completedUploads.map((v: any) => {
        return v.fileId;
      });
    },

    select(event: any) {
      this.chunks = [];
      this.$nextTick(() => {
        this.files = event.target.files;
        this.createChunks();
      });
    },

    startUpload() {
      for (let i = 0; i < this.chunks.length; i++) {
        this.upload(i);
      }
    },

    removeCompletedUpload(i: any) {
      this.completedUploads.splice(i, 1);
      this.$emit('change-count', this.completedUploads.length);
    },

    stopProcessingUpload(i: any) {
      delete this.chunks[i];
    },

    upload(i: any) {
      const formData = new FormData();

      formData.append(
        "finalChunk",
        (this.chunks[i].chunks.length === 1).toString()
      );
      formData.append(
        "file",
        this.chunks[i].chunks[0],
        `${this.chunks[i].fileName}`
      );
      formData.append("upload_identifier", this.chunks[i].uploadIdentifier);

      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/octet-stream"
        },
        data: formData,
        url: `${ApiService.baseUrl}/chunked-upload`,

        onUploadProgress: event => {
          this.chunks[i].progress += event.loaded;
        }
      })
        .then(res => {
          if (this.chunks[i] === undefined) {
            return;
          }

          if (res.data.id) {
            this.chunks[i].uploadIdentifier = res.data.id;
          }

          this.chunks[i].chunks.shift();

          if (this.chunks[i].chunks.length > 0) {
            this.upload(i);
          } else if (res.data.finished) {
            this.completedUploads.push({
              fileId: res.data.id,
              ...this.chunks[i]
            });

            if (this.chunks[i]) {
              delete this.chunks[i];
            }

            this.$emit('change-count', this.completedUploads.length);
          }
        })
        .catch(() => {
          if (this.chunks[i] === undefined) {
            return;
          }

          //TODO: try or quit..
          if (this.chunks[i].chunks.length > 0) {
            this.upload(i);
          }
        });
    },

    createChunks() {
      const size = 500000; //0.5mb.

      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i] as any;
        const amountOfChunks = Math.ceil(file.size / size);

        const chunkData = {
          uploadIdentifier: null,
          fileName: file.name,
          fileSize: file.size,
          progress: 0,
          chunks: [] as any
        };

        for (let x = 0; x < amountOfChunks; x++) {
          chunkData.chunks.push(
            file.slice(
              x * size,
              Math.min(x * size + size, file.size),
              file.type
            )
          );
        }

        this.chunks.push(chunkData);
      }

      this.startUpload();
    },

    async takePicture() {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        saveToGallery: false,
      });

      const data = (image.dataUrl as string) || (image.base64String as string);

      const file = this.urlToFile(
          data,
      );

      this.chunks = [
        {
          uploadIdentifier: null,
          fileName: 'photo.jpg',
          fileSize: file.size,
          progress: 0,
          chunks: [
              file,
          ],
        }
      ];

      this.startUpload();
    },

    urlToFile(url: string, sliceSize=512){
      const arr = url.split(',');

      alert(url);
      //const mimeType = arr[0].match(/:(.*?);/)[1];
      const mimeType = 'txt';

      const bstr = atob(arr[1]);

      const byteArrays = [];

      for (let offset = 0; offset < bstr.length; offset += sliceSize) {
        const slice = bstr.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, {type: mimeType});
    },

    isAndroid() {
      return isPlatform('android');
    },
    async chooseFile() {
      if (!isPlatform('android')) {
        this.file!.click();
        return;
      }

      const sheet = await actionSheetController.create({
        header: this.t('upload.more.title'),
        buttons: [
          {
            text: this.t('upload.more.camera'),
            handler: () => {
              this.takePicture();
            },
          },
          {
            text: this.t('upload.more.video'),
            handler: () => {
              (this.$refs['open-video'] as HTMLInputElement).click();
            },
          },
          {
            text: this.t('upload.more.file'),
            handler: () => {
              this.file!.click();
            },
          },
          {
            text: this.t('upload.more.cancel'),
            role: 'cancel',
          },
        ],
      });

      await sheet.present();
    },
  },
});
